<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-if="fieldWrapper"
      ref="fieldWrapper"
      :field="field"
      :input-group="true"
      :variables-field-active="showToggle"
      @toggle-variables-field="emitToggle"
    >
      <!------------ START: Form field input ------------>
      <div
        ref="input"
        class="form-control"
        :contenteditable="!isDisabled"
        :data-placeholder="placeholder"
        :class="[validationClass, showPlaceholder ? 'empty' : '']"
        v-bind="{ disabled: isDisabled }"
        @input="onContentEditableInput"
        @focus="showVariables = true"
        @keyup.esc="showVariables = !showVariables"
        @keydown.tab="showVariables = false"
      />
      <!------------ END: Form field input ------------>
      <!------------ START: Return type ------------>
      <div class="input-group-append">
        <span
          v-b-popover.hover.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
        >
          <i :class="variableIcon" />
        </span>
      </div>
      <!------------ END: Return type ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
    <div v-else ref="inputGroup" class="input-group">
      <!------------ START: Form field input ------------>
      <div
        ref="input"
        class="form-control"
        :contenteditable="!isDisabled"
        :data-placeholder="placeholder"
        :class="[validationClass, showPlaceholder ? 'empty' : '']"
        v-bind="{ disabled: isDisabled }"
        @input="onContentEditableInput"
        @focus="showVariables = true"
        @keyup.esc="showVariables = !showVariables"
        @keydown.tab="showVariables = false"
      />
      <!------------ END: Form field input ------------>
      <!------------ START: Return type ------------>
      <div class="input-group-append">
        <span
          v-b-popover.hover.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
        >
          <i :class="variableIcon" />
        </span>
      </div>
      <!------------ END: Return type ------------>
    </div>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted"
      :target="$refs.input"
      :errors="validationErrors"
    />
    <!------------ END: Error messages tooltip ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-if="enableVariables && isMounted"
      v-model="showVariables"
      :filter="filter"
      :el="
        fieldWrapper
          ? $refs['fieldWrapper'].$refs['inputGroup']
          : $refs['inputGroup']
      "
      :input-el="$refs['input']"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import { returnTypeIcons } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { base, input } from "@/components/Tools/FormHelper/Helper/mixins";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import { getValidations } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  name: "InputText",
  components: {
    ErrorsTooltip,
    VariablesDropdown,
    FieldWrapper
  },
  mixins: [base, input],
  props: {
    showToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      returnType: "variable"
    };
  },
  validations() {
    return {
      value: getValidations({ variable: true }, true, "text")
    };
  },
  computed: {
    computedValue: {
      get: function () {
        let value = this.$v.value.$model;
        // If input was not touched yet, don't show null
        if (value === null) {
          value = "";
        }
        // Always get value as string to be editable
        return String(value);
      },
      set: function (value) {
        // Set value cast or just as string
        this.lastValueSet = value;
        this.$v.value.$model = value;
      }
    },
    highlightType: function () {
      return "all";
    },
    variableIcon: function () {
      return returnTypeIcons.variable;
    },
    placeholder: function () {
      return this.$t("formHelper.selectVariable");
    }
  },
  watch: {
    // Watch for v-model value and update contenteditable if changed from outside
    value: function (val) {
      if (val !== this.lastValueSet) {
        this.lastValueSet = val;
        this.highlightInput(val);
      }
    }
  },
  mounted() {
    this.init();
    this.isMounted = true;
  },
  methods: {
    init() {
      this.highlightInput(this.value);
    },
    emitToggle() {
      this.$emit("toggle-variables-field");
    }
  }
};
</script>
